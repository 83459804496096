import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, adjustDateToServerTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, UserPermission
} from "../../../utils/utils";
import {getAllPaymentMethods, getReceivableById, userHasPermission} from "../../../utils/requestUtils";
import Config from "../../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import Loading from "../../../core/Loading";

const apiUrl = Config.apiUrl;
const ReportReceivables = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [receivables, setReceivables] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalValue, setTotalValue] = useState(0);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(-1);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const navigate = useNavigate();
    const [hasReportPermission, setReportPermission] = useState(undefined);

    useEffect(() => {
        if (hasReportPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasReportPermission]);


    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/receivables/queryPaidReceivablesByPaymentDate?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${1}&paymentMethodId=${selectedPaymentMethodId}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setReceivables(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.REPORT_RECEIVABLES_DETAILED).then(userHasPermission => setReportPermission(userHasPermission));
        getAllPaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(receivables.reduce((acc, obj) => acc + obj.value, 0));
    }, [receivables]);

    async function removeReceivable(id) {
        try {
            const url = apiUrl + '/api/receivables/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const onPayClick = async (id) => {
        const receivable = await getReceivableById(id);
        if (!window.confirm(`Deseja realmente ${!receivable.paymentDate ? "Baixar" : "Estornar"}?`)) {
            return;
        }
        if (receivable.paymentDate) {
            receivable.paymentDate = undefined;
            receivable.status = 0;
        } else {
            receivable.paymentDate = getOnlyDate(new Date());
            receivable.status = 1;
        }

        const url = apiUrl + '/api/receivables';
        await makeRequest(url, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(receivable));

        await makeQuery();
    };

    function getBackgroundColor(receivable) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(receivable.dueDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (receivable.status === 0) {
            if (dueDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(dueDate, currentDate) === 0) {
                return warningColor;
            }
        }
        return normalColor;
    }

    if(hasReportPermission === undefined) {
        return <Loading/>
    }

    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Fechamento de Caixa Detalhado</div>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>


                    <div style={{display: 'flex', marginTop: '5px', alignItems: 'center'}}>
                        <div>
                        Data:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />




                        </div>
                        </div>
                        <div style={{flexDirection: "column", alignItems: 'center'}}>

                            Forma de Pagamento
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <select value={selectedPaymentMethodId}
                                        onChange={(e) => setSelectedPaymentMethodId(e.target.value)}>
                                    <option value="-1">Todos</option>
                                    {paymentMethods.map((paymentMethod, index) => (
                                        <option key={index} value={paymentMethod.id}>
                                            {paymentMethod.name}
                                        </option>
                                    ))}
                                </select>
                                <button onClick={handleQuery}>Consultar</button>
                            </div>

                        </div>

                    </div>

            </div>

            <table style={{width: '1100px', maxHeight: '100%'}}>

                <tr>
                    <th>
                        Cliente
                    </th>
                    <th>
                        Descrição
                    </th>
                    <th>
                        Forma de Pagamento
                    </th>
                    <th style={{textAlign: 'center'}}>
                        Lançamento
                    </th>
                    <th style={{textAlign: 'center'}}>
                        Vencimento
                    </th>
                    <th style={{textAlign: 'center'}}>
                        Pagamento
                    </th>
                    <th style={{textAlign: 'right'}}>
                        Valor
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {receivables.map((receivable, index) => (


                    <tr style={{backgroundColor: getBackgroundColor(receivable)}}>
                        <td>
                            <div key={index}>{receivable.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.description}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.paymentMethod ? receivable.paymentMethod.name : ''}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{formatDateToString(new Date(receivable.date))}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{formatDateToString(adjustDateToServerTimeZone(new Date(receivable.dueDate)))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'center'}}>{receivable.paymentDate ? formatDateToString(new Date(receivable.paymentDate)) : "Pendente"}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{receivable.value.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>

                    </tr>
                ))}
                </tbody>
                {receivables.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </>
    );
};

export default ReportReceivables;
