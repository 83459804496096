import React from 'react';
import Config from "./config";


const apiUrl = Config.apiUrl;

const UnauthorizedAccess = () => {

    return (
        <div>
            <div className="header-label">Acesso não autorizado.</div>
        </div>
    );
};

export default UnauthorizedAccess;
