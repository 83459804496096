// src/components/Login.js
import React, {useState} from 'react';
import Cookies from 'js-cookie';
import Config from "./core/config";
import {useNavigate} from "react-router-dom";
import sgeImg from "./img/sge-sistemas-logo.png";

const apiUrl = Config.apiUrl;
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();

    const handleLogin = async () => {
        try {

            if (!email || email === '') {
                setLoginError('Informe o e-mail.');
                return;
            }

            if (!password || password === '') {
                setLoginError('Informe a senha.');
                return;
            }


            const basicAuthToken = btoa(`${email}:${password}`); // Cria o token Basic Auth

            const response = await fetch(`${apiUrl}/api/users/login`, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${basicAuthToken}`
                },
            });

            if (response.ok) {
                const userData = await response.json();

                Cookies.set('user', JSON.stringify(userData), {expires: 7});
                Cookies.set('userUUID', userData.uuid, {expires: 7});
                navigate('/app');
                setLoginError(null);
                window.location.reload();
            } else {
                setLoginError('E-mail ou senha inválidos');
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setLoginError('Erro ao fazer login. Tente novamente mais tarde.');
        }
    };

    return (
        <div style={{width: '100%', minHeight: '100vh', backgroundColor: '#D06436'}}>
            <div style={{paddingTop: '15vh'}}>
                <div style={{
                    margin: 'auto',
                    width: '220px',
                    padding: '20px',

                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    backgroundColor: '#ffffff'
                }}>

                    <form>

                        <div style={{marginTop: '5px', alignItems: 'center', width: '200px'}}>
                            <div style={{textAlign: 'center'}}>
                                <img src={sgeImg} alt="Logo" style={{width: '190px'}}/></div>
                            <h2>Login</h2>
                            <div style={{width: '180px'}}>E-mail:</div>
                            <input
                                style={{width: '210px'}}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div style={{marginTop: '5px', alignItems: 'center', width: '200px'}}>
                            <div style={{width: '180px'}}>Senha:</div>
                            <input
                                style={{width: '210px'}}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{marginTop: '5px', alignItems: 'center', width: '200px'}}>
                            <button style={{width: '210px'}} type="button" onClick={handleLogin}>
                                Login
                            </button>
                        </div>
                    </form>
                    {loginError && <p style={{color: 'red'}}>{loginError}</p>}
                </div>
            </div>
        </div>
    );
};

export default Login;
