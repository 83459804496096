import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {downloadSaleFile, findSectorsByQueryString} from "../../utils/requestUtils";
import {searchCEP} from "../../core/Cep";
import {
    adjustDateToBrowserTimeZone,
    brazilianStates,
    renderSaleOptions,
    SaleStatus,
    statusTranslate
} from "../../utils/utils";
import PaymentMethodValueSelect from "../paymentMehod/PaymentMethodValueSelect";
import DatePicker from "react-datepicker";

const apiUrl = Config.apiUrl;

const SaleViewer = ({saleId}) => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const searchParams = new URLSearchParams(window.location.search);
    const id = saleId ? saleId : searchParams.get('id');
    const attentionColor = 'var(--attention-color)';
    const normalColor = 'var(--normal-green-color)';
    const newEntity = {
        id: undefined,
        description: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        paymentMethods: [],
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        value: 0.0,
        shippingCost: 0.0,
        totalValue: 0.0,
        status: 'OPENING',
        productionArea: 'LOCAL',
        deliveryType: 'LOCAL',
        details: '',
        saleDate: '',
        deliveryDate: new Date(),
        sector: undefined,
        files: [],
        receivables: [],
    };

    const [formData, setFormData] = useState(newEntity);
    const [totalValue, setTotalValue] = useState(Number(formData.value) + Number(formData.shippingCost));
    const [sectors, setSectors] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesToRemove, setFilesToRemove] = useState([]);


    useEffect(() => {
        let totalValue = 0.0;
        if (formData.deliveryType === 'DELIVERY') {
            totalValue += Number(formData.shippingCost);
        }
        totalValue += Number(formData.value);
        setTotalValue(totalValue);
    }, [formData]);

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/sales/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                response.deliveryDate = adjustDateToBrowserTimeZone(new Date(response.deliveryDate));
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        findSectorsByQueryString("").then(sectors => setSectors(sectors));
        makeQuery();
    }, [id]);


    return (
        <div>
            <div className="header-label">Serviço - {formData.code}</div>
            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Cliente</div>
                    <input disabled="true" type="text" style={{width: '300px'}} value={formData.customerName}
                           name="customerName"
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Telefone</div>
                    <input disabled="true" type="text" style={{width: '300px'}} value={formData.customerPhone}
                           name="customerPhone"
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Email</div>
                    <input disabled="true" type="email" style={{width: '300px'}} value={formData.customerEmail}
                           name="customerEmail"
                    />
                </div>
            </div>
            <div style={{display: 'flex'}}>
                {formData.user &&
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Vendedor</div>
                        <input disabled={true} value={formData.user.name}/>
                    </div>
                }
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Status</div>
                    <select disabled="true" value={formData.status}>
                        {renderSaleOptions()}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Data de Entrega:</div>
                    <DatePicker
                        disabled={true}
                        className="datepicker-time"
                        selected={formData.deliveryDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeCaption="Hora"
                        isClearable
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Setor</div>
                    <select disabled="true" value={formData?.sector?.id}>
                        <option value="">Selecione o Setor</option>
                        {sectors.map((sector, index) => (
                            <option key={index} value={sector.id}>
                                {sector.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Valor:</div>
                    <input disabled="true" type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                           value={formData.value}
                           name="value"
                    />
                </div>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{height: '17px'}}>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" disabled={true}
                               checked={formData.issueNfSeries1}
                               name="issueNfSeries1"/>
                        <div>Emitir NF série 1?</div>

                    </div>
                </div>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Área de Produção</div>
                <select disabled="true" value={formData?.productionArea}
                        onChange={(e) =>
                            setFormData((prevData) => ({
                                ...prevData,
                                productionArea: e.target.value
                            }))
                        }>
                    <option value="">Selecione a Área de Produção</option>
                    <option key={'LOCAL'} value={'LOCAL'}>
                        {'Local'}
                    </option>
                    <option key={'PARTNER'} value={'PARTNER'}>
                        {'Parceiro'}
                    </option>

                </select>
            </div>

            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Tipo de Entrega</div>
                    <select disabled="true" value={formData?.deliveryType}
                            onChange={(e) =>
                                setFormData((prevData) => ({
                                    ...prevData,
                                    deliveryType: e.target.value
                                }))
                            }>
                        <option value="">Selecione o Tipo de Entrega</option>
                        <option key={'LOCAL'} value={'LOCAL'}>
                            {'Local'}
                        </option>
                        <option key={'DELIVERY'} value={'DELIVERY'}>
                            {'Delivery'}
                        </option>

                    </select>
                </div>
                {formData.deliveryType === 'DELIVERY' && (
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Frete:</div>
                        <input disabled="true" type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                               value={formData.shippingCost}
                               name="shippingCost"
                        />
                    </div>
                )}
            </div>
            {formData.deliveryType === 'DELIVERY' && (
                <>
                    <div style={{display: 'flex'}}>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '80px'}}>CEP:</div>
                            <div>
                                <input disabled="true" style={{width: '80px'}} type="text" id="cep" value={formData.cep}
                                       name="cep"
                                />
                            </div>
                        </div>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Endereço:</div>
                            <input disabled="true" type="text" style={{width: '297px'}} value={formData.address}
                                   name="address"
                                   autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '70px'}}>Número:</div>
                            <input disabled="true" type="number" step="1" placeholder="0" style={{width: '70px'}}
                                   value={formData.addressNumber} name="addressNumber"
                                   autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Complemento:</div>
                            <input disabled="true" type="text" style={{width: '310px'}}
                                   value={formData.addressComplement}
                                   name="addressComplement"
                                   autoComplete={false}/>
                        </div>

                    </div>
                    <div style={{display: 'flex'}}>

                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Bairro:</div>
                            <input disabled="true" type="text" style={{width: '400px'}} id="neighborhood"
                                   value={formData.neighborhood}
                                   name="neighborhood"/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Cidade:</div>
                            <input disabled="true" type="text" style={{width: '290px'}} id="city" value={formData.city}
                                   name="city"/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Estado:</div>
                            <select disabled="true" value={formData.state}>
                                <option value="">Selecione...</option>
                                {brazilianStates.map((estado) => (
                                    <option key={estado.sigla} value={estado.sigla}>
                                        {estado.sigla} - {estado.nome}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Descrição do serviço</div>
                <textarea disabled="true" style={{width: '100%', height: "100px"}} value={formData.description}
                          name="description"
                />
            </div>

            {formData.files.length > 0 &&
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Arquivos</div>
                    <ul style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                        {formData.files.map((file, index) => (
                            <li key={index}
                                style={{display: 'flex', alignItems: 'center', marginBottom: '8px', listStyle: 'none'}}>
                                <a href="" style={{cursor: "pointer"}} onClick={(e) => {
                                    e.preventDefault();
                                    downloadSaleFile(formData.id, file.displayFileName, Cookies.get('userUUID'));
                                }}>
                                    {file.displayFileName}
                                </a>

                            </li>
                        ))}

                        {files.map((file, index) => (
                            <li key={index}
                                style={{display: 'flex', alignItems: 'center', marginBottom: '8px', listStyle: 'none'}}>
                                <a
                                    href={URL.createObjectURL(file)}
                                    download={file.name}
                                >
                                    {file.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            }

            <div className="total-label" style={{color:normalColor, height: '37.6px', display: 'flex', alignItems: "center"}}>
                Pago: R$ {formData.receivables
                .filter(receivable => receivable.status === 1)
                .reduce((sum, receivable) => sum + receivable.value, 0)
                .toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </div>
            <div className="total-label" style={{color: attentionColor, height: '37.6px', display: 'flex', alignItems: "center"}}>
                Restante: R$ {(
                formData.totalValue -
                formData.receivables
                    .filter(receivable => receivable.status === 1)
                    .reduce((sum, receivable) => sum + receivable.value, 0)
            ).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </div>
            <div className="total-label" style={{height: '37.6px', display: 'flex', alignItems: "center"}}>
                Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}
            </div>

        </div>
    )
        ;
};

export default SaleViewer;
