import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, renderSaleOptions, SaleStatus, statusOptions, statusTranslate, UserPermission
} from "../../utils/utils";
import {
    downloadSaleFile,
    findSectorsByQueryString,
    getSaleById,
    updateSaleStatus,
    userHasPermission
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import ModalDiv from "../../ModalDiv";
import {useReactToPrint} from "react-to-print";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import SaleViewer from "./SaleViewer";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;
const SaleList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [sales, setSales] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [status, setStatus] = useState('IN_PROGRESS');
    const [totalValue, setTotalValue] = useState(0);
    const navigate = useNavigate();
    const [sectors, setSectors] = useState([]);
    const [selectedSectorId, setSelectedSectorId] = useState(-1);
    const [saleShowFilesToDownload, setSaleShowFilesToDownload] = useState(undefined);
    const [saleShowPrintDialogIndex, setSaleShowPrintDialogIndex] = useState(undefined);
    const [saleIdToBeShowed, setSaleIdToBeShowed] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasListPermission, setListPermission] = useState(undefined);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);
    const [hasRemovePermission, setRemovePermission] = useState(undefined);

    const [hasShowTotalPermission, setShowTotalPermission] = useState(undefined);

    const componentRef = useRef();


    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/sales/queryByDateAndStatusAndSector?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${status}&sectorId=${selectedSectorId}&searchTerm=${searchTerm}`;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setSales(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    }

    useEffect(() => {
        if (hasListPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasListPermission]);

    useEffect(() => {
        findSectorsByQueryString("").then(sectors => setSectors(sectors));
        userHasPermission(UserPermission.SHOW_TOTAL_SALE_LIST).then(userHasPermission => setShowTotalPermission(userHasPermission));
        userHasPermission(UserPermission.EDIT_SERVICE).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_SERVICE).then(userHasPermission => setCreatePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_SERVICE).then(userHasPermission => setListPermission(userHasPermission));
        userHasPermission(UserPermission.REMOVE_SERVICE).then(userHasPermission => setRemovePermission(userHasPermission));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(sales.reduce((acc, obj) => acc + obj.totalValue, 0));
    }, [sales]);

    async function removeSale(id) {
        try {
            const url = apiUrl + '/api/sales/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    function getWarningDeliveryDateColor(sale) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(sale.deliveryDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = undefined;//'var(--normal-color)';

        if (dueDate < currentDate)
            return errorColor;
        // if (calculateDaysDifference(dueDate, currentDate) === 0) {
        //     return warningColor;
        // }

        return normalColor;
    }

    const [sortConfig, setSortConfig] = useState({key: 'deliveryDate', direction: 'ascending'});

    const [sortedSales, setSortedSales] = useState([]);

    useEffect(() => {
        setSortedSales([...sales].sort((a, b) => {
            const aValue = sortConfig.key.includes('.') ? a[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : a[sortConfig.key];
            const bValue = sortConfig.key.includes('.') ? b[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : b[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    }, [sales, sortConfig]);
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    async function handleStatus(index, status) {
        const response = await updateSaleStatus(sortedSales[index].id, status);
        if (response > 0) {
            setSortedSales((prevItems) =>
                prevItems.map((item, i) => {
                        return i === index ? {
                            ...item,
                            status: status,
                        } : item;
                    }
                )
            );
        } else {
            alert('O status não foi atualizado em nenhum serviço.');
        }
    }

    function printSale(saleIndex) {
        setSaleShowPrintDialogIndex(saleIndex);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: '',
        pageStyle: `
    @page {
        size: A4;
        margin: 0mm !important;
    }
    @media print {
        * {
            font-size: 12px !important;
        }
    }
    `,
    });


    const handleInputChange = (e) => {
        const string = e.target.value;
        setSearchTerm(string);
    };

    if (hasListPermission === undefined
        || hasEditPermission === undefined
        || hasCreatePermission === undefined
        || hasRemovePermission === undefined
        || hasShowTotalPermission === undefined
    ) {
        return <Loading></Loading>;
    }

    return (
        <>
            {saleIdToBeShowed && <ModalDiv closeModal={() => setSaleIdToBeShowed(undefined)}>

                <SaleViewer saleId={saleIdToBeShowed}>
                </SaleViewer>
            </ModalDiv>}
            {saleShowPrintDialogIndex >= 0 && (
                <ModalDiv closeModal={() => setSaleShowPrintDialogIndex(undefined)} style_={{width: '400px'}}>
                    <div ref={componentRef}>
                        <div
                            style={{marginBottom: '5px'}}>CÓDIGO: {sortedSales[saleShowPrintDialogIndex].code}</div>
                        <div
                            style={{marginBottom: '5px'}}>CLIENTE: {sortedSales[saleShowPrintDialogIndex].customerName}</div>
                        <div
                            style={{marginBottom: '5px'}}>CONTATO: {sortedSales[saleShowPrintDialogIndex].customerPhone}</div>
                        <div
                            style={{marginBottom: '5px'}}>COLABORADOR: {sortedSales[saleShowPrintDialogIndex].user.name}</div>
                        <div
                            style={{marginBottom: '5px'}}>PEDIDO: {formatDateToString(new Date(sortedSales[saleShowPrintDialogIndex].saleDate))}</div>
                        <div
                            style={{marginBottom: '5px'}}>ENTREGA: {formatDateTimeToString(new Date(sortedSales[saleShowPrintDialogIndex].deliveryDate))}</div>
                        <div style={{marginBottom: '5px'}}>VALOR:
                            R$ {sortedSales[saleShowPrintDialogIndex].totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        <div style={{marginBottom: '5px'}}>
                            PAGO: R$ {sortedSales[saleShowPrintDialogIndex].receivables
                            .filter(receivable => receivable.status === 1)
                            .reduce((sum, receivable) => sum + receivable.value, 0)
                            .toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>

                        <div style={{marginBottom: '5px'}}>
                            RESTANTE: R$ {(
                            sortedSales[saleShowPrintDialogIndex].totalValue -
                            sortedSales[saleShowPrintDialogIndex].receivables
                                .filter(receivable => receivable.status === 1)
                                .reduce((sum, receivable) => sum + receivable.value, 0)
                        ).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                    </div>
                    <ActionButtonsManagerView>
                        <button onClick={async () => {
                            await handlePrint();
                        }}>Imprimir
                        </button>
                        <button onClick={async () => {
                            await handleStatus(saleShowPrintDialogIndex, SaleStatus.CONCLUDED);
                            setSaleShowPrintDialogIndex(undefined);
                        }}>Concluir
                        </button>
                    </ActionButtonsManagerView>
                </ModalDiv>
            )}
            {saleShowFilesToDownload && (
                <ModalDiv closeModal={() => setSaleShowFilesToDownload(undefined)} style_={{width: '570px'}}>
                    <div>Arquivos</div>
                    <ul style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                        {saleShowFilesToDownload.files.map((file, index) => (
                            <li key={index}
                                style={{display: 'flex', alignItems: 'center', marginBottom: '8px', listStyle: 'none'}}>
                                <a href="" style={{cursor: "pointer"}} onClick={async (e) => {
                                    e.preventDefault();
                                    await downloadSaleFile(saleShowFilesToDownload.id, file.displayFileName, Cookies.get('userUUID'));
                                }}>
                                    {file.displayFileName}
                                </a>

                            </li>
                        ))
                        }
                    </ul>
                </ModalDiv>
            )}
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Consulta de Serviços</div>
                {hasCreatePermission &&
                    <button className={"new-button"} onClick={() => navigate('/sale')}>Novo</button>
                }
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>

                <div style={{marginTop: '5px', alignItems: 'center', display: "flex"}}>
                    <div style={{alignItems: 'center'}}>
                        Código/Nome/Telefone
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="text" value={searchTerm} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Data de Entrega:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Setor
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={selectedSectorId}
                                    onChange={(e) => setSelectedSectorId(e.target.value)}>
                                <option value="-1">Todos</option>
                                {sectors.map((sector, index) => (
                                    <option key={index} value={sector.id}>
                                        {sector.name}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Status
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option key="ALL" value="ALL">
                                    {statusTranslate.ALL.label}
                                </option>
                                <option key="IN_PROGRESS" value="IN_PROGRESS">
                                    {statusTranslate.IN_PROGRESS.label}
                                </option>
                                {renderSaleOptions()}
                            </select>

                            <button onClick={handleQuery}>Consultar</button>
                        </div>
                    </div>

                </div>

            </div>

            <table style={{height: '100%'}}>

                <tr>
                    <th style={{textAlign: 'center', cursor: "pointer", minWidth: "70px"}}
                        onClick={() => handleSort('code')}>
                        {getSortIcon('code')} Código
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('customerName')}>
                        {getSortIcon('customerName')} Cliente
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('sector.name')}>
                        {getSortIcon('sector.name')} Setor
                    </th>
                    <th style={{textAlign: 'center', cursor: "pointer", minWidth: "100px"}}
                        onClick={() => handleSort('saleDate')}>
                        {getSortIcon('saleDate')} Data
                    </th>
                    <th style={{cursor: "pointer", minWidth: "70px", textAlign: 'right'}}
                        onClick={() => handleSort('value')}>
                        {getSortIcon('value')} Valor
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('status')}>
                        {getSortIcon('status')} Status
                    </th>
                    <th style={{textAlign: 'center', cursor: "pointer", minWidth: "100px"}}
                        onClick={() => handleSort('deliveryDate')}>
                        {getSortIcon('deliveryDate')} Entrega
                    </th>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sortedSales.map((sale, index) => (


                    <tr style={{backgroundColor: sale.sector.color}}>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{sale.code}</div>
                        </td>
                        <td>
                            <div key={index}>{sale.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{sale.sector.name}</div>
                        </td>
                        <td>
                            <div key={index}
                                 style={{textAlign: 'center'}}>{formatDateToString(new Date(sale.saleDate))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{sale.totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>

                            <div key={index} style={{
                                display: "flex",
                                backgroundColor: statusTranslate[sale.status].color,
                                borderRadius: '3px',
                                borderColor: statusTranslate[sale.status].color,
                                justifyContent: 'center'
                            }}
                            >
                                <select
                                    style={{
                                        padding: '2px',
                                        backgroundColor: statusTranslate[sale.status].color,
                                        borderColor: statusTranslate[sale.status].color
                                    }}
                                    value={sale.status} onChange={(e) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (!confirm(`Deseja realmente mudar o status do serviço ${sale.code} de '${statusTranslate[sale.status].label}' para '${statusTranslate[e.target.value].label}'?`)) {
                                        return;
                                    }
                                    if (e.target.value === SaleStatus.CONCLUDED) {
                                        alert('É necessário imprimir antes de concluir o serviço!');
                                        printSale(index);
                                        return;
                                    }
                                    handleStatus(index, e.target.value)
                                }}>
                                    {renderSaleOptions()}
                                </select>

                            </div>
                        </td>
                        <td>
                            <div key={index} style={{
                                backgroundColor: getWarningDeliveryDateColor(sale),
                                padding: '3px',
                                borderRadius: '3px'
                            }}>{formatDateTimeToString(new Date(sale.deliveryDate))}</div>
                        </td>
                        <td>

                            <button onClick={async () => {
                                await setSaleIdToBeShowed(sale.id);
                            }}>Ver Serviço
                            </button>

                            {hasEditPermission &&
                                <button onClick={async () => {
                                    await navigate('/sale?id=' + sale.id);
                                }}>Editar
                                </button>
                            }


                            <button onClick={async () => printSale(index)}>
                                Imprimir
                            </button>


                            <button disabled={sale.files.length === 0}
                                    title={sale.files.length === 0 ? "Nenhum arquivo para baixar." : ""}
                                    onClick={async () => {
                                        if (sale.files.length > 1) {
                                            setSaleShowFilesToDownload(sale);
                                        } else {
                                            await downloadSaleFile(sale.id, sale.files[0].displayFileName, Cookies.get('userUUID'));
                                        }
                                    }}>
                                Arquivo
                            </button>

                            {hasRemovePermission &&
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await removeSale(sale.id);
                                    }
                                }}>Excluir
                                </button>
                            }
                        </td>

                    </tr>
                ))}
                </tbody>
                {sales.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            {hasShowTotalPermission &&
                <div style={{marginTop: '5px', alignItems: 'center'}}>

                    <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div>
                </div>
            }

        </>
    );
};

export default SaleList;
