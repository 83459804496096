import React, {useState, useEffect, useRef} from 'react';

import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, renderSaleOptions, SaleStatus, statusOptions, statusTranslate, UserPermission
} from "../../../utils/utils";
import {
    downloadSaleFile,
    findUsersByQueryString,
    getSaleById,
    updateSaleStatus,
    userHasPermission
} from "../../../utils/requestUtils";
import Config from "../../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import ModalDiv from "../../../ModalDiv";
import {useReactToPrint} from "react-to-print";
import ActionButtonsManagerView from "../../common/ActionButtonsManagerView";
import {makeRequest} from "../../../utils/httpRequest";
import Loading from "../../../core/Loading";

const apiUrl = Config.apiUrl;
const ReportSalesByUser = (props) => {

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const [sales, setSales] = useState([]);
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(lastDayOfMonth);
    const [totalValue, setTotalValue] = useState(0);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(-1);

    const [hasReportPermission, setReportPermission] = useState(undefined);

    useEffect(() => {
        if (hasReportPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasReportPermission]);

    const componentRef = useRef();


    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/sales/report/sale_by_user?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&userId=${selectedUserId}`;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});


            setSales(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.REPORT_SALES_BY_USER).then(userHasPermission => setReportPermission(userHasPermission));
        findUsersByQueryString("").then(users => setUsers(users));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(sales.reduce((acc, obj) => acc + obj.totalValue, 0));
    }, [sales]);

    async function removeSale(id) {
        try {
            const url = apiUrl + '/api/sales/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const [sortConfig, setSortConfig] = useState({key: 'totalValue', direction: 'descending'});

    const [sortedSales, setSortedSales] = useState([]);

    useEffect(() => {
        setSortedSales([...sales].sort((a, b) => {
            const aValue = sortConfig.key.includes('.') ? a[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : a[sortConfig.key];
            const bValue = sortConfig.key.includes('.') ? b[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : b[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    }, [sales, sortConfig]);
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    if(hasReportPermission === undefined) {
        return <Loading/>
    }

    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Relatório de Vendas por Vendedor</div>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>

                <div style={{marginTop: '5px', alignItems: 'center', display: "flex"}}>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Data:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Vendedor
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={selectedUserId}
                                    onChange={(e) => setSelectedUserId(e.target.value)}>
                                <option value="-1">Todos</option>
                                {users.map((user, index) => (
                                    <option key={index} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                            <button onClick={handleQuery}>Consultar</button>
                        </div>

                    </div>


                </div>

            </div>

            <table style={{width: '100%', maxHeight: '100%'}}>

                <tr>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('user.name')}>
                        {getSortIcon('user.name')} Vendedor
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'right'}} onClick={() => handleSort('totalValue')}>
                        {getSortIcon('totalValue')} Valor
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sortedSales.map((sale, index) => (


                    <tr>
                        <td>
                            <div key={index}>{sale.user.name}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{sale.totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>

                    </tr>
                ))}
                </tbody>
                {sales.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </>
    );
};

export default ReportSalesByUser;
