import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {downloadSaleFile, findSectorsByQueryString, userHasPermission} from "../../utils/requestUtils";
import {searchCEP} from "../../core/Cep";
import {
    adjustDateToBrowserTimeZone,
    brazilianStates,
    renderSaleOptions,
    SaleStatus,
    statusTranslate, UserPermission
} from "../../utils/utils";
import PaymentMethodValueSelect from "../paymentMehod/PaymentMethodValueSelect";
import DatePicker from "react-datepicker";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;

const SaleManager = () => {

    const DESCRIPTION_MAX_LENGTH = 1000;

    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const [originalTotalValue, setOriginalTotalValue] = useState(undefined);
    const newEntity = {
        id: undefined,
        description: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        paymentMethods: [],
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        value: 0.0,
        shippingCost: 0.0,
        totalValue: 0.0,
        status: 'OPENING',
        productionArea: undefined,
        deliveryType: undefined,
        details: '',
        saleDate: '',
        deliveryDate: undefined,
        sector: undefined,
        files: [],
        issueNfSeries1: false,
    };

    const [formData, setFormData] = useState(newEntity);
    const [totalValue, setTotalValue] = useState(Number(formData.value) + Number(formData.shippingCost));
    const [sectors, setSectors] = useState([]);
    const [showPaymentMethodValueSelect, setShowPaymentMethodValueSelect] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesToRemove, setFilesToRemove] = useState([]);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);

    useEffect(() => {
        if (hasCreatePermission === false || (hasEditPermission === false && id)) {
            navigate('/unauthorizedaccess');
        }
    }, [hasEditPermission, hasCreatePermission]);

    const removeFile = (index) => {
        if (!window.confirm('Deseja realmente excluir o arquivo?')) {
            return;
        }
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFiles((prevFiles) => [...prevFiles, selectedFile]);
        }
    };

    const handleUpload = async (userUUID, saleId, file) => {
        const formData = new FormData();
        formData.append('files', file);  // Altere 'file' para 'files'

        try {
            const response = await fetch(`${apiUrl}/api/sales/${saleId}/upload`, {
                method: 'POST',
                headers: {
                    'useruuid': userUUID,
                },
                body: formData,
            });

            if (response.ok) {
                console.log('Arquivo enviado com sucesso:', file.name);
            } else {
                console.error('Erro ao enviar arquivo:', file.name);
            }
        } catch (error) {
            console.error('Erro de rede ao enviar arquivo:', error);
        }
    };

    const handleRemoveFile = async (userUUID, saleId, fileName) => {
        try {
            const response = await fetch(`${apiUrl}/api/sales/${saleId}/file/${fileName}`, {
                method: 'DELETE',
                headers: {
                    'useruuid': userUUID,
                }
            });

            if (response.ok) {
                console.log('Arquivo excluído com sucesso:', fileName);
            } else {
                console.error('Erro ao excluir arquivo:', fileName);
            }
        } catch (error) {
            console.error('Erro de rede ao excluir arquivo:', error);
        }
    };


    const handleUploadAll = async (userUUID, saleId) => {
        for (const file of files) {
            await handleUpload(userUUID, saleId, file);
        }
    };

    const handleRemoveAllFiles = async (userUUID, saleId, files) => {
        for (const file of files) {
            await handleRemoveFile(userUUID, saleId, file.displayFileName);
        }
    };

    useEffect(() => {
        let totalValue = 0.0;
        if (formData.deliveryType === 'DELIVERY') {
            totalValue += Number(formData.shippingCost);
        }
        totalValue += Number(formData.value);
        setTotalValue(totalValue);
    }, [formData]);
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/sales/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                response.deliveryDate = adjustDateToBrowserTimeZone(new Date(response.deliveryDate));
                setOriginalTotalValue(response.totalValue);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.EDIT_SERVICE).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_SERVICE).then(userHasPermission => setCreatePermission(userHasPermission));
        findSectorsByQueryString("").then(sectors => setSectors(sectors));
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                if (totalValue > 0 && totalValue !== originalTotalValue) {
                    openPaymentMethodValueSelect();
                } else {
                    confirmSale([]);
                }
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    const confirmSale = async (paymentMethodValues) => {
        // setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 300));
        await saveEntity(paymentMethodValues);
        navigate('/salelist');
    }

    function validateInputs() {
        if (!formData.customerName || formData.customerName === '') {
            alert('Preencha o campo Cliente.');
            return false;
        }

        if (!formData.customerPhone || formData.customerPhone === '') {
            alert('Preencha o campo Telefone.');
            return false;
        }

        if (!formData.deliveryDate) {
            alert('Preencha o campo Data de Entrega.');
            return false;
        }

        if (!formData.sector) {
            alert('Preencha o campo Setor.');
            return false;
        }


        if (!formData.value) {
            alert('Preencha o campo Valor.');
            return false;
        }

        if (!formData.productionArea) {
            alert('Preencha o campo Área de Produção.');
            return false;
        }

        if (!formData.deliveryType) {
            alert('Preencha o campo Tipo de Entrega.');
            return false;
        }

        if (formData.deliveryType === 'DELIVERY') {
            if (!formData.cep) {
                alert('Preencha o campo CEP.');
                return false;
            }

            if (!formData.address) {
                alert('Preencha o campo Endereço.');
                return false;
            }

            /* eslint-disable no-restricted-globals */
            if ((!formData.addressNumber || formData.addressNumber === 0) && !confirm('O endereço está zerado. Deseja realmente continuar?')) {
                return false;
            }

            if (!formData.neighborhood) {
                alert('Preencha o campo Bairro.');
                return false;
            }

            if (!formData.city) {
                alert('Preencha o campo Cidade.');
                return false;
            }

            if (!formData.state) {
                alert('Preencha o campo Estado.');
                return false;
            }
        }

        if (!formData.description) {
            alert('Preencha o campo Descrição.');
            return false;
        }


        return true;
    }

    async function saveEntity(paymentMethodValues) {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/sales';
        if (formData.deliveryType === 'LOCAL') {
            formData.address = '';
            formData.addressNumber = 0;
            formData.addressComplement = '';
            formData.neighborhood = '';
            formData.cep = '';
            formData.city = '';
            formData.state = '';
            formData.shippingCost = 0.0;
        }

        if (id) {
            await handleRemoveAllFiles(userUUID, id, filesToRemove);
        }

        formData.paymentMethods = paymentMethodValues;

        const response = await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(formData));

        await handleUploadAll(userUUID, response.id);
    }

    function handleStateChange(idState) {
        setFormData((prevData) => ({
            ...prevData,
            state: idState,
        }));
    }

    function handleSectorSelected(value) {
        if (value) {
            const sector = sectors.find((p) => p.id === Number(value));
            setFormData((prevData) => ({
                ...prevData,
                sector: sector
            }));
        }
    }

    function handleStatusSelected(value) {
        if (value) {
            setFormData((prevData) => ({
                ...prevData,
                status: value
            }));
        }
    }

    async function searchCepAndFillAddressInputs() {

        let result = undefined;
        // setLoading(true);
        try {
            result = await searchCEP(formData.cep);
        } finally {
            // setLoading(false);
        }
        if (result != null) {
            setFormData((prevData) => ({
                ...prevData,
                address: result.street,
                neighborhood: result.neighborhood,
                city: result.city,
                state: result.state,
            }));
        } else {
            alert('CEP não encontrado.');
        }
    }

    const openPaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(true);
    };

    const closePaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(false);
    };

    const handleDeliveryDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            deliveryDate: date
        }))
    };

    function removeFileFromFormData(index) {
        if (!window.confirm('Deseja realmente excluir o arquivo?')) {
            return;
        }
        setFilesToRemove((prevFiles) => [...prevFiles, formData.files[index]]);
        setFormData(prevFormData => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index)
        }));
    }

    if (hasCreatePermission === undefined
        || hasEditPermission === undefined) {
        return <Loading/>
    }

    return (
        <div style={{width: '1000px'}}>
            {showPaymentMethodValueSelect && (
                <PaymentMethodValueSelect onItemClick={confirmSale} onClose={closePaymentMethodValueSelect}
                                          paymentMethods={formData.paymentMethods}
                                          futureDueDate={formData.deliveryDate}
                                          totalValue={totalValue} onConfirm={(paymentMethodValues) => {
                    closePaymentMethodValueSelect();
                    confirmSale(paymentMethodValues);
                }}/>)}

            <div
                className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Serviço {formData.id > 0 ? '-' : ''} {formData.code}</div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Cliente</div>
                    <input maxLength={100} type="text" style={{width: '300px'}} value={formData.customerName} name="customerName"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Telefone</div>
                    <input maxLength={15} type="text" style={{width: '300px'}} value={formData.customerPhone} name="customerPhone"
                           onChange={handleChange}/>
                </div>

                {/*<div style={{marginTop: '5px', alignItems: 'center'}}>*/}
                {/*    <div>Email</div>*/}
                {/*    <input type="email" style={{width: '300px'}} value={formData.customerEmail} name="customerEmail"*/}
                {/*           onChange={handleChange}/>*/}
                {/*</div>*/}
            </div>
            <div style={{display: 'flex'}}>
                {formData.user &&
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Vendedor</div>
                        <input disabled={true} value={formData.user.name}/>
                    </div>
                }
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Status</div>
                    <select value={formData.status} onChange={(e) => handleStatusSelected(e.target.value)}>
                        {renderSaleOptions()}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Data de Entrega:</div>
                    <DatePicker
                        className="datepicker-time"
                        selected={formData.deliveryDate}
                        onChange={handleDeliveryDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeCaption="Hora"
                        isClearable
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Setor</div>
                    <select value={formData?.sector?.id}
                            onChange={(e) => handleSectorSelected(e.target.value)}>
                        <option value="">Selecione o Setor</option>
                        {sectors.map((sector, index) => (
                            <option key={index} value={sector.id}>
                                {sector.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Valor:</div>
                    <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                           value={formData.value}
                           name="value"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{height: '17px'}}>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox"
                               checked={formData.issueNfSeries1}
                               name="issueNfSeries1"
                               onChange={handleChange}/>
                        <div>Emitir NF série 1?</div>

                    </div>
                </div>

            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Área de Produção</div>
                <select value={formData?.productionArea}
                        onChange={(e) =>
                            setFormData((prevData) => ({
                                ...prevData,
                                productionArea: e.target.value
                            }))
                        }>
                    <option value="">Selecione a Área de Produção</option>
                    <option key={'LOCAL'} value={'LOCAL'}>
                        {'Local'}
                    </option>
                    <option key={'PARTNER'} value={'PARTNER'}>
                        {'Parceiro'}
                    </option>

                </select>
            </div>

            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Tipo de Entrega</div>
                    <select value={formData?.deliveryType}
                            onChange={(e) =>
                                setFormData((prevData) => ({
                                    ...prevData,
                                    deliveryType: e.target.value
                                }))
                            }>
                        <option value="">Selecione o Tipo de Entrega</option>
                        <option key={'LOCAL'} value={'LOCAL'}>
                            {'Local'}
                        </option>
                        <option key={'DELIVERY'} value={'DELIVERY'}>
                            {'Delivery'}
                        </option>

                    </select>
                </div>
                {formData.deliveryType === 'DELIVERY' && (
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Frete:</div>
                        <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                               value={formData.shippingCost}
                               name="shippingCost"
                               onChange={handleChange}/>
                    </div>
                )}
            </div>
            {formData.deliveryType === 'DELIVERY' && (
                <>
                    <div style={{display: 'flex'}}>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '80px'}}>CEP:</div>
                            <div>
                                <input style={{width: '80px'}} type="text" id="cep" value={formData.cep} name="cep"
                                       onChange={handleChange}/>
                                <button onClick={searchCepAndFillAddressInputs}>Buscar CEP</button>
                            </div>
                        </div>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Endereço:</div>
                            <input maxLength={255} type="text" style={{width: '297px'}} value={formData.address} name="address"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '70px'}}>Número:</div>
                            <input type="number" step="1" placeholder="0" style={{width: '70px'}}
                                   value={formData.addressNumber} name="addressNumber"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Complemento:</div>
                            <input maxLength={100} type="text" style={{width: '310px'}} value={formData.addressComplement}
                                   name="addressComplement"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                    </div>
                    <div style={{display: 'flex'}}>

                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Bairro:</div>
                            <input maxLength={100} type="text" style={{width: '400px'}} id="neighborhood"
                                   value={formData.neighborhood}
                                   name="neighborhood" onChange={handleChange}/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Cidade:</div>
                            <input maxLength={100} type="text" style={{width: '290px'}} id="city" value={formData.city}
                                   name="city" onChange={handleChange}/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Estado:</div>
                            <select value={formData.state} onChange={(e) => handleStateChange(e.target.value)}>
                                <option value="">Selecione...</option>
                                {brazilianStates.map((estado) => (
                                    <option key={estado.sigla} value={estado.sigla}>
                                        {estado.sigla} - {estado.nome}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Descrição do serviço</div>
                <textarea maxLength={DESCRIPTION_MAX_LENGTH} style={{width: '100%', height: "100px"}}
                          value={formData.description}
                          name="description"
                          onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Arquivos</div>
                <ul style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                    {formData.files.map((file, index) => (
                        <li key={index}
                            style={{display: 'flex', alignItems: 'center', marginBottom: '8px', listStyle: 'none'}}>
                            <button className={'remove-button'} onClick={() => removeFileFromFormData(index)}>
                                Remover
                            </button>
                            <a href="" style={{cursor: "pointer"}} onClick={(e) => {
                                e.preventDefault();
                                downloadSaleFile(formData.id, file.displayFileName, Cookies.get('userUUID'));
                            }}>
                                {file.displayFileName}
                            </a>

                        </li>
                    ))}

                    {files.map((file, index) => (
                        <li key={index}
                            style={{display: 'flex', alignItems: 'center', marginBottom: '8px', listStyle: 'none'}}>
                            <button className={'remove-button'} onClick={() => removeFile(index)}>
                                Remover
                            </button>
                            <a
                                href={URL.createObjectURL(file)}
                                download={file.name}
                            >
                                {file.name}
                            </a>
                        </li>
                    ))}
                </ul>
                <button onClick={() => document.getElementById('fileInput').click()}>
                    Adicionar Arquivo
                </button>
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    style={{display: 'none'}} // Ocultar o input de arquivo
                />
            </div>

            <div className="total-label" style={{height: '37.6px', display: 'flex', alignItems: "center"}}>
                Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    )
};

export default SaleManager;
