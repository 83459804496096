import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {userHasPermission} from "../../utils/requestUtils";
import {UserPermission} from "../../utils/utils";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;
const PaymentMethodList = (props) => {
    let [paymentMethods, setPaymentMethods] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();

    const [hasListPermission, setListPermission] = useState(undefined);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);
    const [hasRemovePermission, setRemovePermission] = useState(undefined);

    useEffect(() => {
        if (hasListPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasListPermission]);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/paymentMethods/query=' + (query && query !== '' ? (query) : '');
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setPaymentMethods(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        userHasPermission(UserPermission.EDIT_PAYMENT_METHOD).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_PAYMENT_METHOD).then(userHasPermission => setCreatePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_PAYMENT_METHOD).then(userHasPermission => setListPermission(userHasPermission));
        userHasPermission(UserPermission.REMOVE_PAYMENT_METHOD).then(userHasPermission => setRemovePermission(userHasPermission));
        makeQuery('');
    }, []);

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };


    async function removePaymentMethod(id) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/paymentMethods/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    if (hasListPermission === undefined
        || hasEditPermission === undefined
        || hasCreatePermission === undefined
        || hasRemovePermission === undefined
    ) {
        return <Loading></Loading>;
    }

    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Consulta de Formas de Pagamento</div>
                {hasCreatePermission &&
                    <button className={"new-button"} onClick={() => navigate('/paymentmethod')}>Novo</button>
                }
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
            </div>
            <table style={{width: '540px'}}>
                <colgroup>
                    <col style={{width: '100px'}}/>
                    <col style={{width: '440px'}}/>
                    <col style={{width: 'auto'}}/>
                </colgroup>

                <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th/>
                </tr>
                </thead>

                <tbody style={{overflowY: 'auto'}}>
                {paymentMethods.map((paymentMethod, index) => (
                    <tr key={index}>
                        <td>{paymentMethod.code}</td>
                        <td>{paymentMethod.name}</td>
                        <td>
                            <div style={{display: 'flex'}}>
                                {
                                    hasEditPermission &&
                                    <button onClick={async () => {
                                        await navigate('/paymentmethod?id=' + paymentMethod.id);
                                    }}>Editar
                                    </button>
                                }
                                {hasRemovePermission &&
                                    <button
                                        className="remove-button"
                                        onClick={async () => {
                                            if (window.confirm('Deseja realmente excluir?')) {
                                                await removePaymentMethod(paymentMethod.id);
                                            }
                                        }}
                                    >
                                        Excluir
                                    </button>
                                }
                            </div>
                        </td>
                    </tr>
                ))}
                {paymentMethods.length === 0 && <tfoot>Nenhum item encontrado.</tfoot>}
                </tbody>
            </table>
        </>
    );
};

export default PaymentMethodList;
