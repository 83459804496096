import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import Config from "../../core/config";
import {adjustDateToBrowserTimeZone, getOnlyDate, UserPermission} from "../../utils/utils";
import {getAllPaymentMethods, getReceivableById, userHasPermission} from "../../utils/requestUtils";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;

const ReceivableManager = (props) => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        customerName: '',
        description: '',
        value: 0.0,
        date: getOnlyDate(new Date()),
        dueDate: getOnlyDate(new Date()),
        paymentDate: undefined,
        status: 0,
    };
    const [formData, setFormData] = useState(newEntity);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);

    useEffect(() => {
        if(hasCreatePermission === false || (hasEditPermission === false && id)) {
            navigate('/unauthorizedaccess');
        }
    }, [hasEditPermission, hasCreatePermission]);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['birthDate']: date
        }));
    };

    const handleKidBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['kidBirthDate']: date
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const response = await getReceivableById(id);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.EDIT_RECEIVABLE).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_RECEIVABLE).then(userHasPermission => setCreatePermission(userHasPermission));
        makeQuery();
    }, [id]);

    useEffect(() => {
        getAllPaymentMethods().then((p) => setPaymentMethods(p));
    }, []);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {

                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                clearEntity();
                navigate('/receivablelist');
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {

        }
    };

    function validateInputs() {

        if (!formData.customerName) {
            alert('Preencha o campo nome do cliente.');
            return false;
        }

        if (!formData.description || formData.description === '') {
            alert('Preencha o campo descrição.');
            return false;
        }

        if (!formData.value || formData.value === 0) {
            alert('Preencha o campo valor.');
            return false;
        }

        if (!formData.date || formData.date === '') {
            alert('Preencha o campo data.');
            return false;
        }

        if (!formData.dueDate || formData.dueDate === '') {
            alert('Preencha o campo data de vencimento.');
            return false;
        }

        return true;
    }

    async function saveEntity() {
        const url = apiUrl + '/api/receivables';
        await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(formData));
    }

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['date']: date
        }));
    };

    const handleDueDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['dueDate']: date
        }));
    };
    const handleReceivableDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            paymentDate: getOnlyDate(date)
        }));
    };


    function handlePaymentMethodSelected(id) {

        if(id) {
            const paymentMethod = paymentMethods.find((p) => p.id === Number(id));
            setFormData((prevData) => ({
                ...prevData,
                paymentMethod: paymentMethod
            }));
        }
    }

    if(hasCreatePermission === undefined
        || hasEditPermission === undefined) {
        return <Loading/>
    }

    return (
        <div style={{width: '300px'}}>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Recebimento</div>

            {formData.id && (
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Código:</div>
                    <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>
                </div>)}

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome do Cliente:</div>
                <input type="text" style={{width: '300px'}} value={formData.customerName} name="customerName"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Descrição:</div>
                <input type="text" style={{width: '300px'}} value={formData.description} name="description"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Forma de Pagamento:</div>
                <select value={formData.paymentMethod ? formData.paymentMethod.id : undefined}
                        onChange={(e) => handlePaymentMethodSelected(e.target.value)}>
                    <option value={undefined}>Selecione uma Forma de Pagamento</option>
                    {paymentMethods.map((paymentMethod, index) => (
                        <option key={index} value={paymentMethod.id}>
                            {paymentMethod.name}
                        </option>
                    ))}
                </select>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Valor:</div>
                <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                       value={formData.value}
                       name="value"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Lançamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.date))}
                    onChange={handleDateChange}
                    isClearable
                />
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Vencimento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.dueDate))}
                    onChange={handleDueDateChange}
                    isClearable
                />
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Pagamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.paymentDate ? adjustDateToBrowserTimeZone(new Date(formData.paymentDate)) : undefined}
                    onChange={handleReceivableDateChange}
                    isClearable
                />
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default ReceivableManager;
